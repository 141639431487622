import {Session} from "./track";
import _ from 'underscore';
import {CityState} from "./common";
import {sprintf} from 'sprintf-js';


export class Envelope {
    subject = '';
    body = '';
    tos = [];
    ccs = [];
    bccs = [];
    attachments = [];

    get fields() {
        return ['subject', 'body', 'tos', 'ccs', 'bccs', 'attachments'];
    }

    static create(envelope) {
        return new Envelope(envelope);
    }

    constructor(envelope) {
        this.copyFrom(envelope);
    }

    copyFrom(envelope) {
        _.each(this.fields, key => {
            this[key] = envelope[key];
        });
    }
}

//Functional implementation to retrieve filter objects
export const RecipientFilters = {
    TRAINEE_STATUS: {
        label: 'TRAINEE_STATUS',
        options: function (getter) {
            const statuses = _.chain(getter['users/traineeStatuses'])
                .sortBy(s => s.status)
                .map(s => {
                    return {
                        value: s,
                        text: s.status
                    }
                })
                .value();
            return statuses;
        }
    },
    TRAINEE_SESSION: {
        label: 'TRAINEE_SESSION',
        options: function (getter) {
            const sessions = _.chain(getter['tracks/getProgramsNoYear'])
                .filter(s => {
                    const twoYearsAgo = (new Date()).getFullYear() - 2;
                    const sessionYear = _.isDate(s.dates[0]) ? s.dates[0].getFullYear() : 0;
                    return !s.canceled && sessionYear >= twoYearsAgo;
                })
                .sortBy(s => s.dates[0])
                .map(s => {
                    return {
                        value: s,
                        text: s.label
                    };
                })
                .value();
            return sessions;
        }
    },
    MENTOR_SESSION: {
        label: 'MENTOR_SESSION',
        options: function (getter) {
            const sessions = _.chain(getter['tracks/getMentorProgramsNoYear'])
                .filter(s => {
                    const twoYearsAgo = (new Date()).getFullYear() - 2;
                    const sessionYear = _.isDate(s.dates[0]) ? s.dates[0].getFullYear() : 0;
                    return !s.canceled && sessionYear >= twoYearsAgo;
                })
                .sortBy(s => s.dates[0])
                .map(s => {
                    return {
                        value: s,
                        text: s.label
                    };
                })
                .value();
            return sessions;
        }
    },
    CONTRACTOR_CITY_STATE: {
        label: 'CONTRACTOR_CITY_STATE',
        options: function(getter) {
            const template = '%s-%s';
            const cityStates = _.uniq((_.chain(getter['organizations/getContractors'])
                .filter(c => {
                    return !_.isEmpty(c.state) && !_.isEmpty(c.city);
                })
                .sortBy(c => {
                    return sprintf(template,
                        _.isEmpty(c.state.stateAbbreviation) ? c.state : c.state.stateAbbreviation,
                        c.city);
                })
                .map(c => {
                    return {
                        value: new CityState(c.city, c.state),
                        text: sprintf(template,
                            _.isEmpty(c.state.stateAbbreviation) ? c.state : c.state.stateAbbreviation,
                            c.city)
                    }
                })
                .value()), true, function (cityState) {
                return cityState.text;
            });
            return cityStates;
        }
    },
    CONTRACTOR_STATE: {
        label: 'CONTRACTOR_STATE',
        options: function (getter) {
            const states = _.uniq((_.chain(getter['organizations/getContractors'])
                .filter(c => {
                    return !_.isEmpty(c.state) && !_.isEmpty(c.state.stateAbbreviation);
                })
                .sortBy(c => c.state.stateAbbreviation)
                .map(c => {
                    return {
                        value: c.state,
                        text: c.state.stateAbbreviation
                    }
                })
                .value()), true, function (state) {
                return state.text;
            });
            return states;
        }
    },
    CONTRACTOR_TYPE: {
        label: 'CONTRACTOR_TYPE',
        options: function (getter) {
            const types = _.chain(getter['organizations/getContractorTypes'])
                .sortBy(t => t.type)
                .map(t => {
                    return {
                        value: t,
                        text: t.type
                    }
                })
                .value();
            return types;
        }
    },
    NONE: {
        label: 'NONE',
        options: function (getter) {
            return [];
        }
    }
};

//Enum of subfilters
export const RecipientSubOptions = {
    //All users
    ALL_ENABLED: {
        label: 'All enabled',
        serverValue: 'ENABLED',
        filter: RecipientFilters.NONE
    },
    ALL: {
        label: 'All',
        serverValue: 'ALL',
        filter: RecipientFilters.NONE
    },
    //Trainee
    BY_TRAINEE_STATUS: {
        label: 'By status...',
        serverValue: 'STATUS',
        filter: RecipientFilters.TRAINEE_STATUS
    },
    BY_TRAINEE_REGISTERED_SESSION: {
        label: 'By registered session...',
        serverValue: 'REGISTERED',
        filter: RecipientFilters.TRAINEE_SESSION
    },
    BY_TRAINEE_SCHEDULED_SESSION: {
        label: 'By scheduled session...',
        serverValue: 'SCHEDULED',
        filter: RecipientFilters.TRAINEE_SESSION
    },
    //Mentor
    ACTIVE: {
        label: 'Active',
        serverValue: 'ACTIVE',
        filter: RecipientFilters.NONE
    },
    INACTIVE: {
        label: 'Inactive',
        serverValue: 'INACTIVE',
        filter: RecipientFilters.NONE
    },
    BY_MENTOR_REGISTERED_SESSION: {
        label: 'By registered session...',
        serverValue: 'REGISTERED',
        filter: RecipientFilters.MENTOR_SESSION
    },
    BY_MENTOR_SCHEDULED_SESSION: {
        label: 'By scheduled session...',
        serverValue: 'SCHEDULED',
        filter: RecipientFilters.MENTOR_SESSION
    },
    BY_MENTOR_TRAINEE_REGISTERED_SESSION: {
        label: 'By trainee with registered session...',
        serverValue: 'TRAINEE_REGISTERED',
        filter: RecipientFilters.TRAINEE_SESSION
    },
    BY_MENTOR_TRAINEE_SCHEDULED_SESSION: {
        label: 'By trainee with scheduled session...',
        serverValue: 'TRAINEE_SCHEDULED',
        filter: RecipientFilters.TRAINEE_SESSION
    },
    //Contractor
    PARTICIPATING: {
        label: 'Participating',
        serverValue: 'PARTICIPATING',
        filter: RecipientFilters.NONE
    },
    NOT_PARTICIPATING: {
        label: 'Not participating',
        serverValue: 'NOT_PARTICIPATING',
        filter: RecipientFilters.NONE
    },
    BY_CITY_STATE: {
        label: 'By city and state...',
        serverValue: 'CITY_STATE',
        filter: RecipientFilters.CONTRACTOR_CITY_STATE
    },
    BY_STATE: {
        label: 'By state...',
        serverValue: 'STATE',
        filter: RecipientFilters.CONTRACTOR_STATE
    },
    BY_TYPE: {
        label: 'By type...',
        serverValue: 'TYPE',
        filter: RecipientFilters.CONTRACTOR_TYPE
    }
};

//Enum of top level filters
export const RecipientTopOptions = {
    TRAINEE: {
        label: 'Trainee',
        serverValue: 'TRAINEE',
        subOptions: [
            RecipientSubOptions.ALL,
            RecipientSubOptions.ALL_ENABLED,
            RecipientSubOptions.BY_TRAINEE_STATUS,
            RecipientSubOptions.BY_TRAINEE_REGISTERED_SESSION,
            RecipientSubOptions.BY_TRAINEE_SCHEDULED_SESSION
        ]
    },
    MENTOR: {
        label: 'Mentor',
        serverValue: 'MENTOR',
        subOptions: [
            RecipientSubOptions.ALL,
            RecipientSubOptions.ALL_ENABLED,
            RecipientSubOptions.ACTIVE,
            RecipientSubOptions.INACTIVE,
            RecipientSubOptions.BY_MENTOR_REGISTERED_SESSION,
            RecipientSubOptions.BY_MENTOR_SCHEDULED_SESSION,
            RecipientSubOptions.BY_MENTOR_TRAINEE_REGISTERED_SESSION,
            RecipientSubOptions.BY_MENTOR_TRAINEE_SCHEDULED_SESSION
        ]
    },
    INSTRUCTOR: {
        label: 'Instructor',
        serverValue: 'INSTRUCTOR',
        subOptions: [
            RecipientSubOptions.ALL,
            RecipientSubOptions.ALL_ENABLED
        ]
    },
    ADMINISTRATOR: {
        label: 'Administrator',
        serverValue: 'ADMINISTRATOR',
        subOptions: [
            RecipientSubOptions.ALL,
            RecipientSubOptions.ALL_ENABLED
        ]
    },
    CONTRACTOR: {
        label: 'Contractor',
        serverValue: 'CONTRACTOR',
        subOptions: [
            RecipientSubOptions.PARTICIPATING,
            RecipientSubOptions.NOT_PARTICIPATING,
            RecipientSubOptions.BY_CITY_STATE,
            RecipientSubOptions.BY_STATE,
            RecipientSubOptions.BY_TYPE
        ]
    }
};

