<template>
    <b-select :id="id" v-model="selectedState" :size="size" :state="calculatedState">
        <option :value="null" disabled> - </option>
        <template  v-for="(country, countryName) in states">
            <optgroup :label="countryName">
                <option v-for="state in country" :value="state">{{state | stateOption}}</option>
            </optgroup>
        </template>
    </b-select>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {State} from '@/model/common';
import {errorToastOptions} from "../../util/formatters";

@Component({
    filters: {
        stateOption: (state) => {
            return sprintf('%s (%s)', state.state, state.stateAbbreviation);
        }
    }
})
export default class StateSelect extends Vue {
    @Prop({type: String, default: null}) id;
    @Prop({type: [State, Object, String], default: null}) value;
    @Prop({type: String, default: null}) size;
    @Prop({type: Boolean, default: true}) state;
    @Prop({type: Boolean, default: false}) showStatus;

    get calculatedState() {
        return this.showStatus ? this.state : null;
    }

    get selectedState() {

        if (this.value instanceof State) {
            return this.value;
        }

        if (_.isString(this.value)) {
            return this.$store.getters['common/getStateByAbbreviation'](this.value);
        }

        return this.value;
    }

    set selectedState(value) {
        this.$emit('input', value);
    }

    get states() {
        return _.groupBy(this.$store.getters['common/states'], 'country');
    }

    async mounted() {
        try {
            await this.$store.dispatch('common/loadStates');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>
<style scoped>

</style>