import _ from "underscore";
import {validEmailAddress} from '@/util/validation';

export class ContactForm {
    firstName = null;
    lastName = null;
    ubcId = null;
    email = null;
    city = null;
    state = null;
    message = null;

    get fields() {
        return ['firstName', 'lastName', 'ubcId', 'email', 'city', 'state', 'message'];
    }

    static create(contactForm) {
        return new ContactForm(contactForm);
    }

    constructor(contactForm) {
        this.copyFrom(contactForm);
    }

    cloneTemplate() {
        return _.reduce(this.fields, (template, key) => {
            template[key] = this[key];
            return template;
        }, {});
    }

    clone() {
        return ContactForm.create(this.cloneTemplate());
    }

    copyFrom(contactForm) {
        _.each(this.fields, key => {
            this[key] = contactForm[key];
        });
    }

    get errors() {
        return {
            firstName: _.isEmpty(this.firstName),
            lastName: _.isEmpty(this.lastName),
            ubcId: false,
            email: _.isEmpty(this.email) || !validEmailAddress(this.email),
            city: _.isEmpty(this.city),
            state: _.isEmpty(this.state),
            message: _.isEmpty(this.message)
        };
    }
}

export const NULL_CONTACT_FORM = ContactForm.create({});