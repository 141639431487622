import {sprintf} from 'sprintf-js';
import Dao from '@/dao/dao';
import {getUnixTime} from "date-fns";

class EmailDao extends Dao {
    async sendPublicMessage(message) {
        try {
            const response = await this.api.post('/message/send', message);
            if (!response.data) {
                throw new Error("Unknown error");
            }
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error sending message: %s', error.data.message);
            throw (new Error(message));
        }
    }

    async sendEmail(envelope) {
        try {
            //Encode attachments as needed
            if (!!envelope.attachments) {
                const encodedAttachments = [];
                for (const file of envelope.attachments) {
                    const encodedFile = await this.encodeFile(file);
                    encodedAttachments.push({
                        value: encodedFile,
                        name: file.name
                    });
                }
                envelope.attachments = encodedAttachments;
            }
            //Send email
            const response = await this.api.post('/email/send', envelope);
            if (!response.data) {
                throw new Error("Unknown error");
            }
            return response.data;
        }
        catch (error) {
            console.error(error);
            const message = sprintf('Error sending message: %s', error.data.message);
            throw (new Error(message));
        }
    }

    //Get email addresses based on user input option map
    async getEmailAddresses(request) {
        try {
            const url = sprintf('/email/addresses?rev=%d', getUnixTime(new Date()));
            const response = await this.api.post(url, request);  //Consider proper implementation as POST-GET
            return response.data;
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading email addresses: %s', err.data.message);
            throw (new Error(message));
        }
    }
}

const emailDao = new EmailDao();
export default emailDao;
