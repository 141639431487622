<template>
    <div class="contact">
        <div class="banner">
            <div class="banner-text">Contact Us</div>
            <img src="@/assets/banners/home_banner.jpg"/>
        </div>
        <b-row class="home-content">
            <b-col>
                Thank you for your interest in the Carpenters International Training Funds Superintendent Career Training.
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col cols="6">
                <address>
                    <p><strong>For more information, contact:</strong></p>
                    <abbr title="Email">Email:</abbr> <a href="mailto:sct@carpenters.org">sct@carpenters.org</a><br/>
                    <abbr title="Phone">Phone:</abbr> (702) 938-1111 x2022
                </address>
                <strong style="color:#F00">OR</strong>
            </b-col>
            <b-col cols="6">
                <address>
                    <strong>Carpenters International Training Fund</strong><br/>
                    212 Carpenters Union Way<br/>
                    Las Vegas, NV  89119
                </address>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col cols="6">
                <b-row>
                    <b-col>
                        <b-form-group
                                label="First Name"
                                label-for="first-name"
                                label-class="required"
                                :state="errorState('firstName')"
                                invalid-feedback="Required">
                            <b-input
                                    id="first-name"
                                    size="sm"
                                    v-model="form.firstName"
                                    :state="errorState('firstName')" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                                label="Last Name"
                                label-for="last-name"
                                label-class="required"
                                :state="errorState('lastName')"
                                invalid-feedback="Required">
                            <b-input
                                    id="last-name"
                                    size="sm"
                                    v-model="form.lastName"
                                    :state="errorState('lastName')" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                                label="UBC ID"
                                label-for="ubc-id">
                            <b-input
                                    id="ubc-id"
                                    size="sm"
                                    v-model="form.ubcId" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                                label="Email"
                                label-for="email"
                                label-class="required"
                                :state="errorState('email')"
                                invalid-feedback="Required">
                            <b-input
                                    id="email"
                                    size="sm"
                                    v-model="form.email"
                                    :state="errorState('email')" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                                label="City"
                                label-for="city"
                                label-class="required"
                                :state="errorState('city')"
                                invalid-feedback="Required">
                            <b-input
                                    id="city"
                                    size="sm"
                                    v-model="form.city"
                                    :state="errorState('city')" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                                label="State/Province"
                                label-for="state"
                                label-class="required"
                                :state="errorState('state')"
                                invalid-feedback="Required">
                            <StateSelect
                                    id="state"
                                    size="sm"
                                    v-model="fullState"
                                    :state="errorState('state')" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                                label="Message"
                                label-for="message"
                                label-class="required"
                                :state="errorState('message')"
                                invalid-feedback="Required">
                            <b-textarea
                                    id="message"
                                    size="sm"
                                    v-model="form.message"
                                    :state="errorState('message')"
                                    invalid-feedback="Required"
                                    placeholder="Leave Your Message Here" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button-group
                                class="float-right">
                            <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="clearForm">Clear</b-button>
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    @click="sendMessage"
                                    :disabled="disableSend">Submit</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="6">
                <b-img
                        class="map"
                        alt="Map to Carpenters International Training Center"
                        src="@/assets/itc_map.png"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'underscore';
import store from "../../store/store";
import {sprintf} from 'sprintf-js';
import {validEmailAddress} from '@/util/validation';
import StateSelect from "../../components/shared/StateSelect";
import {Member} from "../../model/member";
import {Envelope} from "../../model/email";
import emailDao from "@/dao/email_dao";
import {ContactForm, NULL_CONTACT_FORM} from "../../model/contact_form";
import {State} from "../../model/common";
import {errorModalOptions, errorToastOptions} from "../../util/formatters";

@Component({
    components: {
        StateSelect
    }
})

export default class Contact extends Vue {
    form = NULL_CONTACT_FORM.clone();

    clearForm() {
        this.form = NULL_CONTACT_FORM.clone();
    }

    async mounted() {
        try {
            await store.dispatch('common/loadStates');
        }
        catch (error) {
            await this.$bvToast.toast(error.message, errorToastOptions);
        }
        this.clearForm();
    }

    get fullState() {
        if (_.isEmpty(this.form.state)) {
            return null;
        }
        else {
            return _.find(store.getters['common/states'], state => {
                return _.isEqual(state.stateAbbreviation, this.form.state);
            });
        }
    }

    set fullState(value) {
        if (value instanceof State) {
            this.form.state = value.stateAbbreviation;
        }
    }

    errorState(field) {
        return !this.form.errors[field];
    }

    get disableSend() {
        const errors = this.form.errors;
        return _.some(Object.keys(errors), (f) => errors[f]);
    }

    async sendMessage() {
        try {
            await emailDao.sendPublicMessage(this.form);
            await this.$bvModal.msgBoxOk('Your message has been sent', {
                title: 'Message Sent',
                size: 'sm',
                buttonSize: 'sm',
                centered: true
            });
            this.clearForm();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }
}
</script>
<style scoped>
    img.map {
        border: 1px solid #000;
        border-radius: 25px;
    }
</style>
